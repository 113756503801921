export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const parentStaggerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

export const childStaggerVariants = {
  hidden: {
    filter: "blur(40px)",
    y: 200,
    opacity: 0,
  },
  visible: {
    filter: "blur(0)",
    y: 0,
    opacity: 1,
  },
};

export const accessibilityOutline =
  "outline-brand/40 focus:outline focus:outline-offset-1 focus:outline-4";
