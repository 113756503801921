import { ElementType, FC, ReactElement } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { cn } from "@nextui-org/theme";

import { accessibilityOutline } from "@/utils/misc";

const buttonVariants = cva(
  `inline-flex items-center justify-center whitespace-nowrap rounded-xl text-base font-normal transition-all disabled:pointer-events-none disabled:opacity-50 px-3 py-2 active:scale-95 ${accessibilityOutline}`,
  {
    variants: {
      variant: {
        primary:
          "text-blue-300 bg-blue-950/80 hover:text-blue-100 hover:bg-blue-800/80",
        outline:
          "bg-zinc-950 text-zinc-50 hover:bg-zinc-50 hover:text-zinc-950 border border-zinc-50/10",
        secondary:
          "text-zinc-100 hover:text-zinc-200 bg-zinc-800/50 hover:bg-zinc-800/90 backdrop-blur-md py-3 px-6 rounded-full",
      },
    },
    defaultVariants: {
      variant: "outline",
    },
  },
);

interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  Tag?: ElementType;
  href?: string;
  onClick?: (e: any) => void;
  children: ReactElement | string;
  className?: string;
}

const Button: FC<Props> = ({
  Tag = Link,
  children,
  href,
  onClick,
  variant,
  className,
  ...props
}) => {
  return (
    <Tag
      href={href}
      onClick={onClick}
      className={cn(buttonVariants({ variant, className }))}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Button;
